export default [
  {
    path: '/business-cards-types',
    name: 'business-cards-types-list',
    component: () => import('@/views/business-cards-types/list/index.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Business Cards Types', active: true },
      ],
      pageTitle: 'Business Cards Types | BETA',
      rule: 'editor',
      section: 'Business Cards Types',
      allowAnonymous: false,
    },
  },
]
