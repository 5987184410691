export default [
  {
    path: '/business-cards/:id/payment-success',
    name: 'business-cards-payment-success',
    component: () => import('@/views/stripe/stripe-success.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Business Cards', active: true },
      ],
      pageTitle: 'Business Cards | BETA',
      rule: 'editor',
      section: 'Business Cards',
      allowAnonymous: false,
    },
  },
]
