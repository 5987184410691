<template>
  <label class="text-weight-bolder">
    <slot>
      Label Name
    </slot>
  </label>
</template>

<script>
export default {

}
</script>

<style>

</style>