export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Dashboard', active: true },
      ],
      pageTitle: 'Dashboard | BETA',
      rule: 'editor',
      section: 'Dashboard',
      allowAnonymous: false,
    },
  },
   // Invoice
   {
    path: '/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/invoice/invoice-edit/InvoiceEdit.vue'),
  },
  // {
  //   path: '/custom',
  //   name: 'custom',
  //   component: () => import('@/views/user/custom/Custom.vue'),
  // },
]
