import Vue from 'vue'

// axios
import axios from 'axios'
import source from '../constants/source-config'

const { baseURL } = source

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
