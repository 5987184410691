export default [
  {
    path: '/privacy',
    name: 'privacy-policy',
    component: () => import('@/views/docs/Privacy.vue'),
    meta: {
      allowAnonymous: true,
      layout: 'full',
    },
  },

  {
    path: '/pdpa',
    name: 'data-protection-policy',
    component: () => import('@/views/docs/DataProtection.vue'),
    meta: {
      allowAnonymous: true,
      layout: 'full',
    },
  },

  {
    path: '/terms',
    name: 'Terms and conditions',
    component: () => import('@/views/docs/Terms.vue'),
    meta: {
      allowAnonymous: true,
      layout: 'full',
    },
  },
  // {
  //   path: '/user-guide',
  //   name: 'user-guide',
  //   component: () => import('@/views/guide/UserGuide.vue'),
  //   group: ['hiring', 'company'],
  //   meta: {
  //     breadcrumb: [
  //       { title: 'Home', url: '/' },
  //       { title: 'User Guide', active: true },
  //     ],
  //     pageTitle: 'User Guide',
  //     rule: 'editor',
  //     // group: ["superuser", "applicant", "employee", "elevated"],
  //     allowAnonymous: false,
  //   },
  // },
]
