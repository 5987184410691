const debugMode = false

let baseURL = 'https://api.steadylah-network.com'
let socketURL = 'https://realtime.steadylah-network.com'

if (debugMode) {
  baseURL = 'http://localhost:81'
  socketURL = 'http://localhost:3000'
}

export default { baseURL, socketURL }
