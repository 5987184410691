export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Account Information', active: true },
      ],
      pageTitle: 'Account Information',
      rule: 'editor',
      // group: ["superuser", "applicant", "employee", "elevated"],
      allowAnonymous: false,
    },
  },
  {
    path: '/edit-profile',
    name: 'edit-profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Account Information', active: true },
      ],
      pageTitle: 'Account Information',
      rule: 'editor',
      // group: ["superuser", "applicant", "employee", "elevated"],
      allowAnonymous: false,
    },
  },
  // {
  //   path: '/corporate-profile',
  //   name: 'corporate',
  //   component: () => import('@/views/profile/CorporateProfile.vue'),
  //   meta: {
  //     breadcrumb: [
  //       { title: 'Home', url: '/' },
  //       { title: 'Account Information', active: true },
  //     ],
  //     pageTitle: 'Account Information',
  //     rule: 'editor',
  //     // group: ["superuser", "applicant", "employee", "elevated"],
  //     allowAnonymous: false,
  //   },
  // },

  // {
  //   path: '/edit-corporate-profile',
  //   name: 'edit-corporate',
  //   component: () => import('@/views/profile/CorporateProfile.vue'),
  //   meta: {
  //     breadcrumb: [
  //       { title: 'Home', url: '/' },
  //       { title: 'Account Information', active: true },
  //     ],
  //     pageTitle: 'Account Information',
  //     rule: 'editor',
  //     // group: ["superuser", "applicant", "employee", "elevated"],
  //     allowAnonymous: false,
  //   },
  // },
]
