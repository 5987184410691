export default [
  {
    path: '/business-cards',
    name: 'business-cards-list',
    component: () => import('@/views/business-cards/list/index.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Business Cards', active: true },
      ],
      pageTitle: 'Business Cards | BETA',
      rule: 'editor',
      section: 'Business Cards',
      allowAnonymous: false,
    },
  },

  {
    path: '/business-cards/{id}',
    name: 'business-cards-view',
    component: () => import('@/views/business-cards/view/index.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Business Cards', active: true },
      ],
      pageTitle: 'Business Cards | BETA',
      // rule: 'editor',
      section: 'Business Cards',
      allowAnonymous: false,
    },
  },
]
