export default [
  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/login/Login.vue'),
    meta: {
      auth: true,
      allowAnonymous: true,
      redirectIfLoggedIn: true,
      layout: 'full',
    },
  },
  // {
  //   path: '/confirm/:code',
  //   name: 'auth-confirm',
  //   component: () => import('@/views/authentication/reset-password/Confirm.vue'),
  //   meta: {
  //     allowAnonymous: true,
  //     layout: 'full',
  //   },
  // },
  {
    // path: '/auth/register',
    path: '/auth/register:code?',
    name: 'auth-registration',
    component: () => import('@/views/authentication/registration/Register.vue'),
    meta: {
      auth: true,
      allowAnonymous: true,
      layout: 'full',
    },
  },

	{
    path: '/auth/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentication/reset-password/ForgotPasswordForm.vue'),
    meta: {
      auth: true,
      allowAnonymous: true,
      layout: 'full',
    },
  },

  {
    path: '/confirm-reset/:code',
    name: 'confirm-reset',
    component: () => import('@/views/authentication/reset-password/ResetPassword.vue'),
    meta: {
      allowAnonymous: true,
      layout: 'full',
    },
  },

  // {
  //   path: '/confirm-reset/:code',
  //   name: 'confirm-reset',
  //   component: () => import('@/views/authentication/reset-password/ResetPassword.vue'),
  //   meta: {
  //     allowAnonymous: true,
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/auth/forgot',
  //   name: 'auth-forgot',
  //   component: () => import('@/views/authentication/reset-password/ForgotPassword.vue'),
  //   meta: {
  //     allowAnonymous: true,
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/authorise',
  //   name: 'auth-two-factor',
  //   component: () => import('@/views/authentication/login/TwoFactor.vue'),
  //   meta: {
  //     allowAnonymous: true,
  //     layout: 'full',
  //   },
  // },
]
